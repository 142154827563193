import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { createMetaManager } from 'vue-meta';
import {recordClickApi,recordClickApiPerson}from '../src/apis/common.js'
import cookie from 'js-cookie'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';

const url = new URL(window.location.href);
const urlParams = new URLSearchParams(url.search);
const viteCode = urlParams.get('viteCode');
const specialcode = urlParams.get('specialcode');
const sourceWebsite = urlParams.get('sourcewebsite');

//客户进来,有访问参数会保存在cookie并且发送接口.客户去别的页面则参数消失,那么不会调接口,但是cookie依然存在
console.log('团队链接码是', specialcode,sourceWebsite);
if (viteCode) {
    console.log('有个人访问码',viteCode);
    //测试(localhost8081)
    // cookie.set('viteCode', viteCode, { expires: 30 })
    //生产
cookie.set('viteCode', viteCode, { domain: '.iprocket.io', path: '/', expires: 30 })
    recordClickApiPerson({
        viteCode:cookie.get('viteCode')
    })
}
if (specialcode && sourceWebsite) {
    console.log('有团队访问码', specialcode, sourceWebsite);
    //测试(localhost8081)
    // cookie.set('inviteCode', specialcode, {expires: 30 })
    // cookie.set('sourceWebsite', sourceWebsite, { expires: 30 })
    //生产
    cookie.set('specialcode', specialcode, { domain: '.iprocket.io', path: '/', expires: 30 })
cookie.set('sourceWebsite', sourceWebsite, { domain: '.iprocket.io', path: '/', expires: 30 })
    recordClickApi({
        sourceWebsite: cookie.get('sourceWebsite'),
        viteCode: cookie.get('specialcode')
    })
}


AOS.init({
  duration: 2000,
  easing: 'ease-in-out-back'
});
// app.config.warnHandler = () => { };
// onMounted(() => {
//     document.dispatchEvent(new Event('render-event'))
// })
createApp(App).use(store)
  .use(router)
  .use(
    createMetaManager(false, {
      description: { tag: 'meta', nameless: true },
      title: { tag: 'meta', nameless: true }
    })
  ).use(ElementPlus).use(Antd).mount('#app');
